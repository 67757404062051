// FeedStoryProvider.js
import { connect } from 'react-redux';
import { goBack } from 'redux-first-history';

import { MERGE_OPERATION_DATA } from '../ActionTypes.js';
import { SHOWCASE_FEED } from '../RemoteConfigKeys.js';

import getMeData from '../selector/getMeData.js';
import fetchFeeds from '../action/fetchFeeds.js';
import getListData from '../selector/getListData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getOperationData from '../selector/getOperationData.js';
import getHomeListItems from '../selector/getHomeListItems.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';

import { FeedNameType } from '../resource/feedConstants.js';
import getTranslationKey from '../resource/getTranslationKey.js';

// HACK: make the selectPath with same category be same reference
const selectPathMap = new Map();

const getSelectPath = category => {
  if (!selectPathMap.has(category)) {
    selectPathMap.set(category, ['home', FeedNameType.MESSAGES, category]);
  }

  return selectPathMap.get(category);
};

const mapStateToProps = (
  state,
  { category, titleI18nId: propsTitleI18nId }
) => {
  const selectPath = getSelectPath(category);
  const nextPage = getListData(state, selectPath, 'nextPage');
  const lastPage = getListData(state, selectPath, 'lastPage');
  const totalCount = getListData(state, selectPath, 'totalCount');
  const showcaseFeed = getRemoteConfigData(state, SHOWCASE_FEED);
  const messageIds =
    category === 'home'
      ? getHomeListItems(state, showcaseFeed)
      : getListData(state, selectPath, 'itemIds');
  const badgeName = category === 'home' ? 'new' : category;
  const scrollLeft = getOperationData(state, ['home', badgeName], 'scrollLeft');

  return {
    isAuthed: !!getMeData(state, 'token'),
    badgeName,
    moreLink: `/story-categories/${category}`,
    messageIds,
    pinnedMessageIds: getListData(
      state,
      ['home', FeedNameType.MESSAGES, category, 'pinned'],
      'itemIds'
    ),
    isNextPageFetching: !!getNetworkingData(
      state,
      [...selectPath, nextPage],
      'isFetching'
    ),
    isFirstPageFetched: !!getNetworkingData(
      state,
      [...selectPath, 1],
      'isFetched'
    ),
    nextPage,
    lastPage,
    totalCount,
    deviceWidth: getOperationData(state, ['device'], 'width'),
    maxPlaceholdersAmount: 10,
    category,
    titleI18nId: propsTitleI18nId || getTranslationKey({ key: category }),
    scrollLeft,
    listPath: selectPath,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchFeeds: data => dispatch(fetchFeeds(data)),
    setScrollLeft: ({ scrollLeft, category }) => {
      return dispatch({
        type: MERGE_OPERATION_DATA,
        payload: {
          selectPath: ['home', category],
          data: {
            scrollLeft,
          },
        },
      });
    },
    goBack: () => dispatch(goBack()),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    fetchFirstPage: (data = {}) =>
      dispatchProps.fetchFeeds({
        type: ownProps.category,
        unixTimestamp: ownProps.feedUnixTimestamp,
        ...data,
      }),
    fetchNextPage: () =>
      dispatchProps.fetchFeeds({
        page: stateProps.nextPage,
        type: ownProps.category,
        unixTimestamp: ownProps.feedUnixTimestamp,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps);
