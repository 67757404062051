// fetchFeeds.js
'use strict';
import parseLinkHeader from 'parse-link-header';

import fetch from '../resource/customFetch.js';
import { getFeedNameType, parseQueryOptions } from '../resource/feedUtils.js';
import { FeedNameType } from '../resource/feedConstants.js';
import getResourceUrl from '../resource/getResourceUrl.js';
import { getIsIos10, getIsMicrosoftEdge } from '../resource/getUserAgent.js';
import objectifyArrayById from '../resource/objectifyArrayById.js';
import { getHeaders } from '../resource/fetchOptionHeader.js';
import handleFetchError from '../resource/handleFetchError.js';
import getMeData from '../selector/getMeData.js';
import getNetworkingData from '../selector/getNetworkingData.js';
import getOperationData from '../selector/getOperationData.js';
import getListData from '../selector/getListData.js';
import savePosts from '../action/savePosts.js';
import { MessageMergeBehavior } from '../reducer/messages.js';
import {
  ADD_HASHTAGS,
  ADD_CATEGORIES,
  ADD_USERS,
  ADD_LIST_ITEMS,
  SET_LIST_ITEMS,
  SET_NETWORKING_FETCHING,
  SET_NETWORKING_SUCCESS,
  SET_NETWORKING_ERROR,
  CLEAR_NETWORKING_NODES,
  MERGE_OPERATION_DATA,
} from '../ActionTypes.js';

const ONE_MINUTE = 1000 * 60;

export const getReturnType = getFeedNameType;

/**
 * Fetch home feeds
 * @kind action
 * @param {number} [ page = 1 ] - page number.
 * @param {number} [ limit = 10 ] - page size.
 * @param {string} type - feed name.
 * @param {string} id - feed id.
 * @param {object} {[httpProxyHeaders = {}]} - http proxy headers for SSR.
 * @param {boolean} {[shouldSkipLocalCache = false]} - should skip 1 minute local cache.
 * @param {boolean} {[isAllowSameId = false]} - is allow same id in lists.
 * @param {boolean} {[shouldReplaceIds = false]} - should replace ids.
 * @param {boolean} {[shouldIgnorePage = false]} - should ignore page.
 * @param {number} {[unixTimestamp]} - feed updated unix timestamp.
 * @param {array} {[selectPath]} - expected select path.
 * @param {array} {[prependIds = []]} - prepend ids.
 * @return {Promise} Action promise.
 */
const fetchFeeds =
  ({
    page = 1,
    limit = 10,
    type,
    id,
    httpProxyHeaders = {},
    shouldSkipLocalCache = false,
    shouldReplaceIds = false,
    isAllowSameId = false,
    shouldIgnorePage = false,
    unixTimestamp,
    selectPath,
    prependIds = [],
  }) =>
  async (dispatch, getState) => {
    if (!type) {
      return dispatch({ type: '' });
    }
    const state = getState();
    const returnType = getReturnType({ feedName: type });
    const lastRenewFeedId = getOperationData(
      state,
      ['fetchedRenewFeedId', type],
      'id'
    );
    const listSelectPath = selectPath || ['home', returnType, type];
    const pinnedListSelectPath = [...listSelectPath, 'pinned'];
    const latestMessageListSelectPath = ['home', FeedNameType.MESSAGES, type];
    const networkingSelectPath = [...listSelectPath, page];
    const lastFetchedTimestamp =
      getNetworkingData(state, [...networkingSelectPath], 'fetchedTimestamp') ||
      0;
    const shouldFetchNewData =
      shouldSkipLocalCache ||
      (!!id && id !== lastRenewFeedId) || // feed id trigger from feed.updated event
      Date.now() >= lastFetchedTimestamp + ONE_MINUTE ||
      (unixTimestamp != null &&
        unixTimestamp >= Math.floor(lastFetchedTimestamp / 1000));

    if (!shouldFetchNewData) {
      return dispatch({ type: '' });
    }

    const token = getMeData(state, 'token');
    const headers = new Headers({
      ...getHeaders(),
      ...httpProxyHeaders,
    });

    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }

    const fetchOptions = {
      method: 'GET',
      headers,
    };

    // Don't use renew feed id on first page, first page need to use type to get latest feed id
    const feedId = id || (page === 1 ? null : lastRenewFeedId) || type;
    const currentNextPage = getListData(state, listSelectPath, 'nextPage');
    const currentNextPageUrl = getListData(
      state,
      listSelectPath,
      'nextPageUrl'
    );

    let url = null;
    if (currentNextPageUrl && page === currentNextPage) {
      url = new URL(currentNextPageUrl);
    } else {
      const search = new URL(type, 'https://swag.live').search;
      const options = [feedId, search];
      if (page && !shouldIgnorePage) {
        options.push(`?page=${page}`);
      }
      if (limit) {
        options.push(`?limit=${limit}`);
      }
      if (getIsIos10() || getIsMicrosoftEdge()) {
        options.push('?no-redirect=1');
      }
      if (unixTimestamp) {
        options.push(`?_=${unixTimestamp}`);
      }
      const { feedNameWithQuery } = parseQueryOptions({
        options,
      });
      url = getResourceUrl({
        endpoint: `/feeds/${feedNameWithQuery}`,
      });
    }

    const stateBeforeFetching = getState();

    dispatch({
      type: SET_NETWORKING_FETCHING,
      payload: { selectPath: networkingSelectPath },
    });

    try {
      let response = await fetch(url.href, fetchOptions);

      if (!response.ok) {
        response = await handleFetchError({
          response,
          dispatch,
          getState,
          fetchOptions,
          fetchUrl: url,
        });
      }

      const totalCount = parseInt(response.headers.get('x-total-count'), 10);
      const etag = response.headers.get('etag');
      const links = parseLinkHeader(response.headers.get('Link'));
      const nextPageUrl = links?.next?.url;
      const nextPage = links?.next && parseInt(links.next.page, 10);
      const lastPage = links?.last && parseInt(links.last.page, 10);
      let itemIds = [];
      let pinnedItemIds;
      let latestMessageIds;

      const payload = await response.json();

      if (type?.startsWith('shorts_') && !payload?.length && page > 1) {
        // shorts_ feed has weird pagination behavior
        // https://swaglive.slack.com/archives/C9TN39WQ0/p1697797549025789
        return dispatch({
          type: SET_NETWORKING_SUCCESS,
          payload: { selectPath: networkingSelectPath, etag },
        });
      }

      if (returnType === FeedNameType.MESSAGES) {
        itemIds = payload.map(item => item.id);
        pinnedItemIds = payload
          .filter(item => item?.metadata?.pinned != null)
          .map(item => item.id);

        dispatch(
          savePosts({
            posts: payload,
            behavior: MessageMergeBehavior.KEEP_OLD,
          })
        );
      } else if (returnType === FeedNameType.USERS) {
        const users = objectifyArrayById({
          array: payload.map(user => {
            delete user.isFollowing;
            return user;
          }),
        });
        itemIds = Object.keys(users);
        dispatch({ type: ADD_USERS, payload: { users } });

        const messages = payload
          .filter(user => user.metadata && user.metadata.latestMessage)
          .map(user => {
            return {
              ...user.metadata.latestMessage,
              senderId: user.id,
            };
          });

        latestMessageIds = messages.map(message => message.id);

        dispatch(
          savePosts({
            posts: messages,
            behavior: MessageMergeBehavior.KEEP_OLD,
          })
        );
      } else if (returnType === FeedNameType.HASHTAGS) {
        const hashtags = payload.reduce((accumulator, current) => {
          const { id: hashtag, metadata } = current;
          accumulator[hashtag] = {
            hashtag,
            messageCount: metadata.messageCount,
            latestMessage: metadata.latestMessage,
          };
          return accumulator;
        }, {});
        itemIds = Object.keys(hashtags);
        dispatch({ type: ADD_HASHTAGS, payload: { hashtags } });
      } else if (returnType === FeedNameType.CATEGORIES) {
        const categories = payload.reduce((accumulator, current) => {
          const { id: category, metadata } = current;
          accumulator[category] = {
            category,
            messageCount: metadata.messageCount,
            latestMessage: metadata.latestMessage,
          };
          return accumulator;
        }, {});
        itemIds = Object.keys(categories);
        dispatch({ type: ADD_CATEGORIES, payload: { categories } });
      }

      const renewFeedId = new URL(response.url).pathname.replace('/feeds/', '');
      if (renewFeedId !== feedId) {
        dispatch({
          type: MERGE_OPERATION_DATA,
          payload: {
            selectPath: ['fetchedRenewFeedId', type],
            data: {
              id: renewFeedId,
            },
          },
        });
      }

      // need to use getState() because last fetching may not finished when this time starts.
      const newState = getState();

      // Since `SET_NETWORKING_FETCHING` will replace last etag value, we need to use state before `SET_NETWORKING_FETCHING` dispatch.
      const lastEtag = getNetworkingData(
        stateBeforeFetching,
        networkingSelectPath,
        'etag'
      );
      const isEtagChanged = etag === null || lastEtag !== etag;

      const lastNextPage = getListData(newState, listSelectPath, 'nextPage');
      // to tell if we fetched page 2
      const isNextPageChanged = lastNextPage !== nextPage;

      const isFirstPage = shouldIgnorePage ? false : page === 1;

      // first page use SET to update list items, so need to invalid all cache.
      // avoiding can not fetch other page's data by this mechanism.
      // more detail here: https://github.com/swaglive/swag-webapp/issues/1271
      if (isFirstPage && isNextPageChanged) {
        dispatch({
          type: CLEAR_NETWORKING_NODES,
          payload: {
            selectPaths: [networkingSelectPath.slice(0, -1)],
          },
        });
      }
      if (isEtagChanged || isNextPageChanged) {
        dispatch({
          type:
            isFirstPage || shouldReplaceIds ? SET_LIST_ITEMS : ADD_LIST_ITEMS,
          payload: {
            selectPath: listSelectPath,
            itemIds: [...prependIds, ...itemIds],
            totalCount,
            nextPage,
            lastPage,
            nextPageUrl,
            isAllowSameId,
          },
        });
      }
      if (pinnedItemIds) {
        dispatch({
          type: isFirstPage ? SET_LIST_ITEMS : ADD_LIST_ITEMS,
          payload: {
            selectPath: pinnedListSelectPath,
            itemIds: pinnedItemIds,
          },
        });
      }
      if (latestMessageIds) {
        dispatch({
          type: isFirstPage ? SET_LIST_ITEMS : ADD_LIST_ITEMS,
          payload: {
            selectPath: latestMessageListSelectPath,
            itemIds: latestMessageIds,
            totalCount,
            nextPage,
            lastPage,
            nextPageUrl,
          },
        });
      }

      return dispatch({
        type: SET_NETWORKING_SUCCESS,
        payload: { selectPath: networkingSelectPath, etag },
      });
    } catch (error) {
      return dispatch({
        type: SET_NETWORKING_ERROR,
        payload: { selectPath: networkingSelectPath, error },
      });
    }
  };

export default fetchFeeds;
